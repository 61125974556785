.question {
    padding: 1rem;
    text-align: left;

    @include media-breakpoint-up(md) {
        padding: 3rem 10rem;
    }

    @include media-breakpoint-up(xl) {
        padding: 3rem 20rem;
    }

    &:nth-child(even) {
        background: $gray-100;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 1rem;

        > div {
            margin: 0.3rem 1rem;
        }
    }

    &__header {
        font-size: 1.5rem;
        font-weight: 600;
    }

    &__title {
        color: $secondary;
        font-size: 1.75rem;
        font-weight: 300;
    }

    &__subText {
        color: $secondary;
        font-size: 1.25rem;
        font-weight: 300;
    }

    &--disabled {
        .question__header {
            color: lighten($secondary, 30);
        }

        .question__title {
            color: lighten($secondary, 30);
        }
    }
}

.question-review {
    padding: 0.5rem 0;

    @include media-breakpoint-up(md) {
        padding: 1rem 0;
    }

    &__header {
        font-size: 0.9rem;
        margin: 0;
    }

    &__title {
        font-size: 0.9rem;
        color: $gray-600;
        margin-bottom: 0.2rem;
    }

    &__answer {
        font-size: 0.8rem;
        color: #222;
    }
}
