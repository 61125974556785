.navbar {
    .container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;

        @include media-breakpoint-up(md) {
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__logo {
        max-width: 250px;

        img {
            max-width: 250px;
            height: auto;
            margin-top: 2px;
        }

        &__txt {
            text-align: right;
            color: $primary;
            font-size: $small-font-size;
        }
    }
}

.navbar-brand {
    font-weight: bold;
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
    }
}
