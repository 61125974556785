.section {
    text-align: center;
    margin-top: 5rem;

    &__header {
        font-size: 1.9rem;
    }
    &__title {
        color: $secondary;
        font-size: 1.9rem;
        font-weight: 400;
        margin-bottom: 3rem;
    }
}

.section-review {
    padding: 1rem;
    text-align: left;

    @include media-breakpoint-up(md) {
        padding: 3rem 10rem;
    }

    @include media-breakpoint-up(xl) {
        padding: 3rem 20rem;
    }

    &:nth-child(even) {
        background: $gray-100;
    }

    &__header {
        font-size: 1.3rem;
        font-weight: 600;
    }
    &__title {
        font-size: 1.3rem;
        font-weight: 300;
        color: $secondary;

        &-wrapper {
            display: flex;
            justify-content: space-between;
        }
    }
}
