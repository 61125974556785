.dot {
    background-color: $primary;
    width: 0.4rem;
    height: 0.4rem;
    margin: 0.3rem;
    border-radius: 50%;

    &--off {
        background-color: $gray-500;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
}
