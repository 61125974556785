@font-face {
    font-family: 'UKNumberPlate';
    src: url('../assets/fonts/UKNumberPlate.eot');
    src: url('../assets/fonts/UKNumberPlate.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/UKNumberPlate.woff') format('woff'),
        url('../assets/fonts/UKNumberPlate.ttf') format('truetype'),
        url('../assets/fonts/UKNumberPlate.svg') format('svg');
}

.vrm-font {
    font-family: "UKNumberPlate", "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    &:-moz-placeholder { /* Firefox 18- */
        font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
}
