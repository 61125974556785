.site-footer {
    padding: 3rem;

    &__skyline-background {
        bottom:0;
        width:100%;
        z-index: -1;
        height:155px;
        position:absolute;

        background-repeat: repeat-x;
        background-position: center bottom;
        background-image: url("./assets/footer_town_bg.png");
    }
}
