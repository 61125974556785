.form-main {
    &__container {
        margin: 0 auto;
        max-width: 40rem;
    }

    &__input {
        font-size: 1.5rem;
        text-align: center;

        &::placeholder {
            color: $gray-500;
            font-size: 1.25rem;
        }
    }

    &__btn {
        width: 100%;
        max-width: 5rem;
        font-size: 1.5rem;
    }
}

.input-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;

    > div {
        margin: 0 0.3rem;
        
        > p {
            font-size: 0.8rem;
        }
    }
}

.mileage-input {
    width: 15rem; /* equal to .text-input */
    margin: 0 auto;
    position: relative;

    &__label {
        position: absolute;
        right: 0px;
        margin: 0.5rem 3rem 0 0;
    }
}

.text-input {
    width: 15rem;
    margin: 0 auto;
}

.field {
    &.is-invalid,
    &.is-error {
        input,
        .custom-file-label {
            border-color: $danger;
        }

        &:after {
            color: $danger;
        }

        >small {
            display: block;
        }
    }

    &__message {
        display: none;
        color: $gray-600;
    }
}
