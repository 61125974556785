.terms {
    &__section:nth-child(odd) {
        background: $gray-100;
    }

    &__text {
        color: $gray-600;
        font-size: 0.9rem;
    }
}
