.btn-secondary {
    color: #fff;
}

.btn-primary {
    color: #fff;
}

.btn-forward {
    color: $gray-100;
}

.btn:disabled, .btn-primary:disabled, .btn-secondary:disabled, .btn-forward:disabled {
    color: $gray-100;
    border-color: $gray-500;
    background-color: $gray-500;
}
