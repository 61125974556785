.help-card {
    border: 0;
    margin-bottom: 8px;
}

.help-card-header {
    background-color: #ffffff;
    background-image: url("./assets/plus.svg");
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: .6rem;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    padding: .25rem 1.25rem;

    &__text {
        text-align: left;
        font-size: .9rem;
        color: $link-color;
    }
}

.help-card-body {
    &__text {
        color: $gray-600;
        font-size: .9rem;
    }
}