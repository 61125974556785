.cc-window {
    text-align: center;
    position: fixed;
    bottom: 0;
    z-index: 99999;
    width: 100%;
    padding: 1rem;
    background-color: #e6e6e6;
    box-shadow: 2px 2px 10px rgba(0,0,0,.3);

    @include media-breakpoint-up(md) {
        border-radius: 5px;
        margin: 1rem;
        padding: .8rem;
        width: 450px;
    }
}

.cc-window__content p {
    font-size: $font-size-sm;
    margin-right: .5rem;
}

.cc-window__dismiss .btn{
    padding: .3rem 3rem;
    font-weight: 700;
    margin: 0 auto;
}
