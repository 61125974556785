// its necessary to font-icon package
@font-face {
    font-family: $icon-font-name;
    src: url('#{$font-path}/icons.eot?62lx5y');
    src: url('#{$font-path}/icons.eot?62lx5y#iefix') format('embedded-opentype'),
         url('#{$font-path}/icons.ttf?62lx5y') format('truetype'),
         url('#{$font-path}/icons.woff?62lx5y') format('woff'),
         url('#{$font-path}/icons.svg?62lx5y#icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.h-alt {
    font-weight: 400;
    color: $secondary;
}

p, li {
    font-size: .9rem;
    color: $gray-600;
}