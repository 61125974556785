.contact {
    margin-top: 3rem;

    &__title {
        text-align: center;
    }

    &__message {
        color: $secondary;
        text-align: center;
        font-size: 1.25rem;
        margin: 1rem;
    }

    &__subtitle {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    &__phone-email {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        margin: 2rem 0 0;
        color: $secondary;
    }

    &__link {
        margin-left: 0.5rem;
        color: $primary;
        border-bottom: 1px dotted $primary;

        &:hover {
            color: $primary;
            border-bottom-style: solid;
        }
    }

    p {
        margin-bottom: 0.25rem;
    }

    .link {
        font-size: 1rem;
    }

    &__section {
        padding: 1rem;

        @include media-breakpoint-up(md) {
            padding: 3rem 10rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 3rem 20rem;
        }

        &:nth-child(even) {
            background-color: $gray-100;
        }
    }

    &__info {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &__item {
            padding: 1rem;
            min-width: 350px;
        }
    }
}
